import { Component, OnDestroy, OnInit } from '@angular/core';
import { TenantDetail } from '../models/tenant';
import { ActivatedRoute, Router } from '@angular/router';
import { TenantsService } from '../services/tenants.service';
import { DeploymentSummary } from '../models/deployment';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-tenant-form',
    templateUrl: './tenant-form.component.html',
    styleUrls: ['./tenant-form.component.scss']
})
export class TenantFormComponent implements OnInit, OnDestroy {

    public tenant: TenantDetail | undefined;
    public deployment: DeploymentSummary | undefined;
    public deploymentId = 0;
    public showJson = false;
    public namePatterns = { 'P': { pattern: new RegExp('[a-zA-Z0-9-]') } };
    private subs = new SubSink();

    constructor(
        private _route: ActivatedRoute,
        private _router: Router,
        private _tenantService: TenantsService
    ) { }

    ngOnInit(): void {
        this.deploymentId = this._route.snapshot.params['id'];

        this.subs.sink = this._route.data.subscribe(
            ({ tenant }) => {
                if (tenant) {
                    this.tenant = tenant;
                } else {
                    this.tenant = new TenantDetail({ deploymentId: this.deploymentId });
                }
            });
    }

    save() {
        if (this.tenant) {
            this.subs.sink = this._tenantService.saveDeploymentTenant(this.deploymentId, this.tenant).subscribe((d) => {
                this._router.navigate(['/deployments', d.deploymentId]);
            });
        } else {
            throw new Error("tenant is undefined");
        }
    }

    delete() {
        if (!this.tenant) {
            throw new Error("tenant is undefined");
        }

        if (confirm(`
Are you sure you want to delete this tenant: ${this.tenant?.name} ?

This will delete all configuration associated with this tenant.

This cannot be undone.`)) {

            this.subs.sink = this._tenantService.deleteTenant(this.deploymentId, this.tenant.tenantId).subscribe(() => {
                this._router.navigate(['/deployments', this.deploymentId]);
            });
        }
    }

    // TODO: Upgrade the prompts and toast with a better UI option
    upgradeTenantDb() {
        if (!this.tenant) {
            throw new Error("tenant is undefined");
        }

        const tenantName = this.tenant.name;

        if (confirm(`Are you sure you want to upgrade the database for tenant "${tenantName}"?`)) {
            this.subs.sink = this._tenantService.upgradeTenantDb(this.tenant.deploymentId, this.tenant.tenantId).subscribe({
                next: () => {
                    alert(`Database upgrade for tenant "${tenantName}" was started successfully.`);
                },
                error: (error) => {
                    alert(`Database upgrade for tenant "${tenantName}" failed to start.  Error message: ${error ? error.message : 'unknown'}`);
                }
            });
        }
    }

    ssrsAuthTypeChange(updateEvent: Event) {
        const target = updateEvent.target as HTMLSelectElement;
        if (this.tenant && target) {
            this.tenant.tenantConfiguration.ssrsConfiguration.authType = +target.value;
        }
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }

}
